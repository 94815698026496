import fetch from "../utils/fetch";

export const goodsDetail = (item_id, item_active_type, activity_id) =>
  fetch("/api", {
    api_name: "V1.item.item.getItemInfo",
    item_id: item_id,
    item_active_type: item_active_type,
    activity_id: activity_id
  });
// 获取团购商品的用户开团列表
export const getGroupList = (group_buy_item_id, page = 0, listRows = 1000) =>
  fetch("/api", {
    api_name: "V1.item.item.getGroupItemOpenList",
    group_buy_item_id: group_buy_item_id,
    page: page,
    listRows: listRows
  });
//客服接口
export const ServicePhone = () =>
  fetch("/api", {
    api_name: "V1.system.system.getServiceType"
  });
// 判断商品是否包邮， 第三方商品是否有货;
export const checkGoods = data =>
  fetch(
    "/api",
    Object.assign(
      {
        api_name: "V1.item.item.checkItem"
      },
      data
    )
  );
// 商品分类
export const goodsClass = () =>
  fetch("/api", {
    api_name: "V1.item.item.getItemClassList"
  });

// 添加到购物车
export const addCar = (
  item_id,
  item_property_id,
  number,
  limit_buy_item_id,
  integral_usage
) =>
  fetch("/api", {
    api_name: "V1.Order.ShopCart.addShopCart",
    item_id: item_id,
    item_property_id: item_property_id,
    number: number,
    limit_buy_item_id: limit_buy_item_id,
    integral_usage: integral_usage
  });

// 添加/取消收藏
export const collectGoods = item_id =>
  fetch("/api", {
    api_name: "V1.User.User.addCollect",
    item_id: item_id
  });

// 商品评价列表
export const goodsEvaluate = (item_id, page, listRows) =>
  fetch("/api", {
    api_name: "V1.item.evaluate.evaluateList",
    item_id: item_id,
    page: page,
    listRows: listRows
  });

// 秒杀限购剩余数量
export const getLimitBuyNum = (limit_buy_id, limit_buy_item_id) =>
  fetch("/api", {
    api_name: "V1.item.item.getLimitBuyNum",
    limit_buy_id: limit_buy_id,
    limit_buy_item_id: limit_buy_item_id
  });
