<template>
  <div class="yqt-image">
    <img
      v-if="lazyload"
      key="lazyload-img"
      class="yqt-image__inner"
      v-bind="$attrs"
      v-on="$listeners"
      v-lazy="lazyloadObj"
      :style="imageStyle"
      :class="{
        'yqt-image__inner--center': alignCenter,
      }"
    />
    <img
      v-else
      key="default-img"
      class="yqt-image__inner"
      v-bind="$attrs"
      v-on="$listeners"
      :src="src"
      :style="imageStyle"
      :class="{
        'yqt-image__inner--center': alignCenter,
      }"
    />
  </div>
</template>

<script>
const loadingImg = require("@/static/image/loading_320x320.gif");
const isSupportObjectFit = () =>
  document.documentElement.style.objectFit !== undefined;
const ObjectFit = {
  NONE: "none",
  CONTAIN: "contain",
  COVER: "cover",
  FILL: "fill",
  SCALE_DOWN: "scale-down",
};
export default {
  name: "YqtImage",
  inheritAttrs: false,
  props: {
    src: String,
    fit: String,
    zIndex: {
      type: Number,
      default: 2000,
    },
    lazyload: {
      type: Boolean,
      default: false,
    },
    loadingImg: {
      type: String,
      default: loadingImg,
    },
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
    };
  },
  computed: {
    imageStyle() {
      const { fit } = this;
      if (!this.$isServer && fit) {
        return isSupportObjectFit()
          ? { "object-fit": fit }
          : this.getImageStyle(fit);
      }
      return {};
    },
    alignCenter() {
      return (
        !this.$isServer && !isSupportObjectFit() && this.fit !== ObjectFit.FILL
      );
    },
    lazyloadObj() {
      let obj = {};
      if (this.lazyload) {
        obj = {
          src: this.src,
          loading: this.loadingImg,
        };
      }
      return obj;
    },
  },
  mounted() {},
  methods: {
    handleLoad(e, img) {
      this.imageWidth = img.width;
      this.imageHeight = img.height;
    },
    handleError(e) {
      this.$emit("error", e);
    },
    /**
     * simulate object-fit behavior to compatible with IE11 and other browsers which not support object-fit
     */
    getImageStyle(fit) {
      const { imageWidth, imageHeight } = this;
      const { clientWidth: containerWidth, clientHeight: containerHeight } =
        this.$el;
      if (!imageWidth || !imageHeight || !containerWidth || !containerHeight)
        return {};
      const imageAspectRatio = imageWidth / imageHeight;
      const containerAspectRatio = containerWidth / containerHeight;
      if (fit === ObjectFit.SCALE_DOWN) {
        const isSmaller =
          imageWidth < containerWidth && imageHeight < containerHeight;
        fit = isSmaller ? ObjectFit.NONE : ObjectFit.CONTAIN;
      }
      switch (fit) {
        case ObjectFit.NONE:
          return { width: "auto", height: "auto" };
        case ObjectFit.CONTAIN:
          return imageAspectRatio < containerAspectRatio
            ? { width: "auto" }
            : { height: "auto" };
        case ObjectFit.COVER:
          return imageAspectRatio < containerAspectRatio
            ? { height: "auto" }
            : { width: "auto" };
        default:
          return {};
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yqt-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.yqt-image__inner {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.yqt-image__inner--center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
}
</style>
