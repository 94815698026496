var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-box", style: _vm.theBorder },
    [
      _vm.type === "router"
        ? _c(
            "router-link",
            {
              staticClass: "search-input",
              attrs: {
                tag: "div",
                to: {
                  name: "search",
                  query: {
                    subject_id: this.$store.state.subject_id,
                    category_id: this.$store.state.category_id,
                  },
                },
              },
            },
            [
              _c("span", { staticClass: "placeholder" }, [
                _vm._v(_vm._s(_vm.placeholder)),
              ]),
              _c("i", { staticClass: "icon icon-search" }),
            ]
          )
        : _vm._e(),
      _vm.type === "search"
        ? _c("div", { staticClass: "h64" }, [
            _c("div", { staticClass: "search-input", class: _vm.borderName }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search_val,
                    expression: "search_val",
                  },
                ],
                attrs: {
                  type: "search",
                  autofocus: "autofocus",
                  placeholder: _vm.placeholder,
                },
                domProps: { value: _vm.search_val },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.search_val = $event.target.value
                    },
                    _vm.input,
                  ],
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
              }),
            ]),
            _c(
              "span",
              {
                class: [_vm.showStues ? "search-btn" : "search-btnClose"],
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.search.apply(null, arguments)
                  },
                },
              },
              [_vm._v("搜索")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }