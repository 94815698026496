<template>
  <div class="category">
    <div class="top-box">
      <div class="search-content">
        <search-box></search-box>
      </div>
    </div>
    <div style="flex: 1; overflow-y: auto; position: relative">
      <yd-scrolltab :callback="callScrollTab" :index="scroll_index">
        <yd-scrolltab-panel
          :label="item.class_name"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <div
            class="content"
            v-for="(classItem, secIndex) in item.sort_list"
            :key="'01' + secIndex"
          >
            <div class="content-title">
              <span class="dian"></span>
              <span class="line"></span>
              <span class="text">{{ classItem.sort_name }}</span>
              <span class="line"></span>
              <span class="dian"></span>
            </div>
            <div class="goods-content">
              <div
                class="goods-item"
                v-for="(goods, oindex) in classItem.category_list"
                :key="'02' + oindex"
                @click="goodsDetail(goods.id, goods.category_name)"
              >
                <div class="goods-img" v-if="is_img_show">
                  <yqt-image
                    v-if="goods.category_img"
                    class="img"
                    :src="
                      goods.category_img
                        | setHttps
                        | ossProcess('style/w_120_h_auto')
                    "
                    fit="cover"
                    lazyload
                    alt="goods-img"
                  />
                  <yqt-image
                    v-else
                    class="img"
                    src="../../assets/classifyIcon-kongbai.png"
                    fit="cover"
                    lazyload
                    alt="goods-img"
                  />
                </div>
                <div class="goods-name">{{ goods.category_name }}</div>
              </div>
            </div>
          </div>
        </yd-scrolltab-panel>
      </yd-scrolltab>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
import searchBox from "@/components/search/SearchBox";
import FootNav from "@/components/footer/FootNav.vue";
import { goodsClass } from "@/services/goodsApi.js";
import { setStore, getStore, wxShare } from "@/utils/common";
import YqtImage from "@/components/image/Image";

export default {
  name: "category",
  data() {
    return {
      goodsList: [],
      scroll_index: 0,
      is_img_show: false, // 是否展示分类图片
    };
  },
  inject: ["reload"],
  components: {
    searchBox,
    FootNav,
    YqtImage,
  },
  methods: {
    //返回当前索引
    callScrollTab(e) {
      this.scroll_index = e;
      console.log(this.scroll_index);
      setStore("scroll_index", e);
    },
    goodsDetail(id, name) {
      this.$router.push({
        path: "/special_topic",
        query: {
          category_id: id,
          name: name,
        },
      });
    },
    async getGoodsClass() {
      try {
        let data = await goodsClass();
        if (data.code === 0) {
          this.goodsList = data.data;
        }
      } catch (err) {
        throw err;
      }
    },
    // 判断是否展示分类图片
    bindImgShow() {
      if (process.env.VUE_APP_SHOW_CATE_IMG_BIGS) {
        let bids = JSON.parse(process.env.VUE_APP_SHOW_CATE_IMG_BIGS);
        bids.forEach((val) => {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.is_img_show = true;
            return;
          }
        });
      }
    },
  },
  mounted() {
    this.bindImgShow();
    this.getGoodsClass();
    // console.log(this.scroll_index);
    // this.$nextTick(() => {
    //   let index =Number.parseInt(getStore('scroll_index'))
    //   console.log(index);
    //   this.scroll_index = index?index:0;
    // });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "home" || from.name === "special_topic") {
        vm.reload();
      }
    });
    wxShare();
  },
};
</script>
<style>
.category .yd-scrolltab-nav {
  background: #fff;
}

.category .yd-scrolltab-item {
  display: block;
  line-height: 0.9rem;
  padding: 0;
  width: 1.8rem;
  height: 0.9rem;
  text-align: center;
  background: #f2f2f2;
  position: relative;
}

.category .yd-scrolltab-content-title {
  display: none;
}

.yd-scrolltab-item:after {
  height: 0;
}

.category .yd-scrolltab-active .yd-scrolltab-title {
  font-weight: bold;
  color: #333;
}

.category .yd-scrolltab-active::after {
  content: "";
  width: 3px;
  height: 0.4rem;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 0;
  margin-top: -0.2rem;
}

.category .yd-scrolltab-title {
  width: 1.8rem;
  max-width: 1.8rem;
}

.category .yd-scrolltab-icon {
  display: none;
}

.yd-scrolltab-content::-webkit-scrollbar {
  display: none;
}

.yd-scrolltab-item:before,
.yd-scrolltab-nav:after {
  width: 0;
}
</style>
<style scoped lang="less">
@import "../../common/less/variable.less";

.category {
  height: 100%;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 0.96rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .top-box {
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: fixed;
    top: 0;

    .search-content {
      width: 6.9rem;
      height: 0.64rem;
    }
  }

  .content {
    padding-top: 0.26rem;
    box-sizing: border-box;
    position: static;

    .content-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 3.22rem;
      height: 0.54rem;
      border-radius: 0.54rem;
      /*background: rgba(108, 87, 255, 0.1);*/
      margin: 0 auto;
      padding: 0 0.25rem;
      box-sizing: border-box;

      .dian {
        width: 2px;
        height: 2px;
        background: #333333;
        border-radius: 2px;
      }

      .line {
        width: 0.56rem;
        height: 1px;
        background: #333333;
      }

      .text {
        font-size: 0.24rem;
        color: #333333;
      }
    }

    .goods-content {
      display: flex;
      flex-wrap: wrap;

      .goods-item {
        width: 1.6rem;
        margin-top: 0.2rem;
        margin-right: 0.2rem;

        .goods-img {
          width: 1.6rem;
          height: 1.6rem;
          margin-bottom: 5px;

          .img {
            width: 1.6rem;
            height: 1.6rem;
            // border-radius: 4px;
          }
        }

        .goods-name {
          text-align: center;
          font-size: 0.24rem;
          color: #666666;
          /*border: 1px solid #666666;*/
          border-radius: 0.5rem;
          height: 0.6rem;
          line-height: 0.6rem;
          background: rgba(108, 87, 255, 0.1);
        }
      }

      .goods-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
