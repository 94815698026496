<template>
  <div class="search-box" :style="theBorder">
    <router-link
      tag="div"
      :to="{
        name: 'search',
        query: {
          subject_id: this.$store.state.subject_id,
          category_id: this.$store.state.category_id
        }
      }"
      class="search-input"
      v-if="type === 'router'"
    >
      <span class="placeholder">{{ placeholder }}</span>
      <i class="icon icon-search"></i>
    </router-link>
    <div v-if="type === 'search'" class="h64">
      <div class="search-input" :class="borderName">
        <input
          type="search"
          v-model="search_val"
          autofocus="autofocus"
          @input="input"
          @keyup.enter="search"
          :placeholder="placeholder"
        />
      </div>
      <span
        :class="[showStues ? 'search-btn' : 'search-btnClose']"
        @click.stop="search"
        >搜索</span
      >
    </div>
  </div>
</template>

<script>
import { inputMixins } from "@/mixins";
import { mapState } from "vuex";
import { isWeiXin, getStore } from "@/utils/common.js";
export default {
  name: "SearchBox",
  props: {
    type: {
      type: String,
      default: "router"
    },
    search_val: String,
	config: Object
  },
  mixins: [inputMixins],
  data() {
    return {
      placeholder: "搜索商品", // 占位符
      keyword: "", // 关键词
      subject_id: this.$route.query.subject_id || "",
      category_id: this.$route.query.category_id || "",
      min_price: "", // 最低价
      max_price: "", // 最高价
      priceToggle: false, // 价格切换，默认false,升序
      rowToggle: false, // 样式切换， 默认false,显示两列
      showStues: true,
      closeBid: [1436],
	  theBorder: null,  // 边框
	  borderName: null  
    };
  },
  computed: {
    ...mapState({
      //商家id
      business_id: state => state.businessInfo.id || "",
      userInfo: state => state.userInfo //用户信息
    })
  },
  methods: {
    // 发起搜索
    search() {
      this.$emit("search", this.keyword);
    },
    // 输入
    input() {
      this.$emit("input", this.keyword);
    },
    closeRk() {
      if (!isWeiXin()) {
        if (this.closeBid.includes(this.business_id)) {
          this.showStues = false;
        }
      }
    },
	// 白色背景色，无背景图时边框处理
	setBorder() {
		const status = getStore('borderStatus');
		if (this.$route.name !== 'home' && this.$route.name !== 'search') {
			this.theBorder = {
				border: '1px solid #E0E2E5'
			}
		}
		if (status === 'true') {
			this.theBorder = {
				border: '1px solid #E0E2E5'
			}
		} else {
			this.borderName = 'input-border';
		}
	}  
  },
  created() {
    this.closeRk();
	this.setBorder();
  },
  watch: {
    keyword(val) {
      this.$emit("changeKeyword", val);
    },
    search_val(val) {
      this.keyword = val;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.search-box {
  .wh(100%, 0.64rem);
  // border: 1px solid @color-row-line;
  border-radius: 0.08rem;
  overflow: hidden;
  .h64 {
    position: relative;
    height: 0.64rem;
  }
  .search-btn {
    height: 0.64rem;
    line-height: 0.64rem;
    padding: 0 0.3rem;
    color: #fff;
    background: var(--main-color);
    /*border: 1px solid var(--main-color);*/
    position: absolute;
    right: 0;
    top: 0rem;
    border-bottom-right-radius: 0.08rem;
    border-top-right-radius: 0.08rem;
    z-index: 5;
  }
  .search-btnClose {
    height: 0.64rem;
    line-height: 0.64rem;
    padding: 0 0.3rem;
    color: #fff;
    background: var(--main-color);
    /*border: 1px solid var(--main-color);*/
    position: absolute;
    right: 0.3rem;
    top: 1rem;
    border-bottom-right-radius: 0.08rem;
    border-top-right-radius: 0.08rem;
    z-index: 5;
  }
  .input-border {
	border: 1px solid @color-row-line;  
  }
  .search-input {
    position: relative;
    .fj;
    align-items: center;
    height: 100%;
    padding-left: 0.4rem;
    /*padding-right: 0.3rem;*/
    z-index: 1;
	background-color: #FFFFFF;

    .placeholder {
      .ct;
      left: 0.4rem;
      .sc(@fontsize-medium, @color-light-grey-s);
      line-height: 0.44rem;
    }

    input {
      flex: 1;
      .sc(@fontsize-medium, @color-dark-grey);
      line-height: 0.44rem;

      &::-webkit-input-placeholder {
        .sc(@fontsize-medium, @color-light-grey-s);
      }
    }

    .icon {
      .ct;
      right: 0.3rem;
      display: inline-block;
      .wh(0.32rem, 0.32rem);
      z-index: 2;
    }

    .icon-search {
      .bis("../../assets/icon_home_search");
    }
  }
}
</style>
