var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category" },
    [
      _c("div", { staticClass: "top-box" }, [
        _c("div", { staticClass: "search-content" }, [_c("search-box")], 1),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            flex: "1",
            "overflow-y": "auto",
            position: "relative",
          },
        },
        [
          _c(
            "yd-scrolltab",
            { attrs: { callback: _vm.callScrollTab, index: _vm.scroll_index } },
            _vm._l(_vm.goodsList, function (item, index) {
              return _c(
                "yd-scrolltab-panel",
                { key: index, attrs: { label: item.class_name } },
                _vm._l(item.sort_list, function (classItem, secIndex) {
                  return _c(
                    "div",
                    { key: "01" + secIndex, staticClass: "content" },
                    [
                      _c("div", { staticClass: "content-title" }, [
                        _c("span", { staticClass: "dian" }),
                        _c("span", { staticClass: "line" }),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(classItem.sort_name)),
                        ]),
                        _c("span", { staticClass: "line" }),
                        _c("span", { staticClass: "dian" }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "goods-content" },
                        _vm._l(
                          classItem.category_list,
                          function (goods, oindex) {
                            return _c(
                              "div",
                              {
                                key: "02" + oindex,
                                staticClass: "goods-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.goodsDetail(
                                      goods.id,
                                      goods.category_name
                                    )
                                  },
                                },
                              },
                              [
                                _vm.is_img_show
                                  ? _c(
                                      "div",
                                      { staticClass: "goods-img" },
                                      [
                                        goods.category_img
                                          ? _c("yqt-image", {
                                              staticClass: "img",
                                              attrs: {
                                                src: _vm._f("ossProcess")(
                                                  _vm._f("setHttps")(
                                                    goods.category_img
                                                  ),
                                                  "style/w_120_h_auto"
                                                ),
                                                fit: "cover",
                                                lazyload: "",
                                                alt: "goods-img",
                                              },
                                            })
                                          : _c("yqt-image", {
                                              staticClass: "img",
                                              attrs: {
                                                src: "../../assets/classifyIcon-kongbai.png",
                                                fit: "cover",
                                                lazyload: "",
                                                alt: "goods-img",
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "goods-name" }, [
                                  _vm._v(_vm._s(goods.category_name)),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                }),
                0
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("foot-nav"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }